<template>
  <van-empty description="客服当前不在线，你可以拨打商家电话">
    <van-button icon="phone-o" round type="danger" class="bottom-button" @click="onCall">
      {{ store.tel }}
    </van-button>
  </van-empty>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    // const useRouter = inject('useRouter')
    const useRoute = inject('useRoute')
    const state = reactive({
      store: {},
      storeId: Cookies.get('storeId') ?? useRoute.query.storeId ?? ''
    })
    const init = () => {
      post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        state.store = res.data
      })
    }
    const onCall = () => {
      window.location.href = 'tel://' + state.store.tel
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onCall
    }
  }
}
</script>
